@import '@telia-front/sass-tokens/index.scss';

.tv-comparison-v1 {
  .tv-comparison__table {
    table tr:first-child th {
      border-top: 0;
    }

    table tr td:first-child,
    table tr th:first-child {
      border-left: 0;
    }

    table tr td:last-child,
    table tr th:last-child {
      border-right: 0;
    }

    table thead tr th,
    table tbody tr.tv-comparison__bordered-row td {
      border-bottom-width: 1px;
    }

    table thead tr th {
      border-bottom-color: inherit;
    }

    .tv-comparison__price-cell,
    .tv-comparison__name-cell,
    .tv-comparison__info-cell {
      @media (max-width: $media-width-sm-max) {
        border-style: dotted;
      }
    }

    .tv-comparison__price-cell {
      @media (max-width: $media-width-sm-max) {
        height: 53px;
      }
    }

    .tv-comparison__row {
      @media (max-width: $media-width-sm-max) {
        border-top-style: dotted;
      }
    }

    .tv-comparison__name-cell {
      @media (max-width: $media-width-sm-max) {
        border-top-style: solid;
      }
    }

    .tv-comparison__price-row {
      @media (max-width: $media-width-sm-max) {
        border-bottom: 1px solid $grey;
      }
    }

    .tv-comparison__channel-info-cell {
      @media (max-width: $media-width-sm-max) {
        padding-top: 0;
      }
    }

    .tv-comparison__sound-and-subtitles {
      @media (max-width: $media-width-sm-max) {
        margin-top: 0;
      }
    }
  }
}

tr.tv-comparison__theme {
  .tv-comparison__table table & {
    border-top: 0;

    > td {
      border-top: 0;
    }
  }
}

.tv-comparison__accordion {
  .accordion__content {
    padding: 0;
  }

  .accordion__header-inner {
    padding-left: 10px;
  }
}

.tv-comparison__header {
  padding: 30px 10px 10px;

  @media (min-width: $media-width-md-min) {
    padding: 10px 0;
  }
}

.tv-comparison__filter {
  padding: 18px 0 0;

  @media (min-width: $media-width-md-min) {
    padding: 32px 0 0;
  }
}

.tv-comparison-v2 {
  .table.table--sticky-header-level-2 {
    overflow-x: initial;
  }

  .table table {
    tbody tr {
      border-top: 1px solid var(--color-neutral-border-subtlest);
    }

    thead tr {
      border-bottom: 1px solid var(--color-neutral-border-subtle);
    }
  }

  .tv-comparison__name {
    @media (min-width: $media-width-md-max) {
      padding-left: 0;
    }

    @media (max-width: $media-width-sm-max) {
      padding: 0 6px;
    }
  }

  .affix {
    z-index: 3;
  }

  tbody tr:last-child {
    border-bottom: 1px solid var(--color-neutral-border-subtlest);
  }

  .table--bordered table td {
    border-style: solid;
    border-color: var(--color-neutral-border-subtlest);
  }

  .table--bordered table th {
    border: none;
  }

  .tv-comparison__scroll-header {
    .table {
      @media (max-width: $media-width-xs-max) {
        overflow-x: hidden;

        th {
          min-width: 120px;
        }

        th:first-child {
          z-index: 3;
          position: sticky;
          left: 0;
          min-width: 160px;
          background: var(--color-neutral-bg-level-2);
        }
      }
    }
  }

  .tv-comparison__scroll-body {
    .table {
      overflow-y: hidden;

      td:first-child {
        border-right: 1px solid var(--color-neutral-border-subtlest);
      }

      @media (max-width: $media-width-xs-max) {
        overflow-x: auto;

        table {
          border-collapse: separate;
        }

        td {
          min-width: 120px;
          border-left-width: 0;
          border-top-width: 0;
        }

        tr:first-child {
          td {
            border-top-width: 1px;
          }
        }

        td:first-child {
          position: sticky;
          border-left-width: 1px;
          border-right: none;
          background: var(--color-neutral-bg-level-1);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
          left: 0;
          z-index: 2;
          min-width: 160px;
        }
      }
    }
  }
}
