@import '@telia-front/sass-tokens/index.scss';

.sort-indicator {
  @include reset-button();

  position: relative;
  cursor: pointer;
  width: 100%;
}

.sort-indicator__text {
  display: inline-block;
  padding-right: 15px;
}

.sort-indicator__arrows {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}

.sort-indicator__arrows-up,
.sort-indicator__arrows-down {
  padding-left: 15px;
}

.sort-indicator__arrows-up {
  &::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    top: 25%;
    right: 0;
    border-top: 1px solid $grey-d;
    border-left: 1px solid $grey-d;
    transform: rotate(45deg);
  }

  .sort-indicator--asc & {
    &::before {
      border-color: $black;
    }
  }
}

.sort-indicator__arrows-down {
  &::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    bottom: 25%;
    right: 0;
    border-bottom: 1px solid $grey-d;
    border-left: 1px solid $grey-d;
    transform: rotate(-45deg);
  }

  .sort-indicator--desc & {
    &::before {
      border-color: $black;
    }
  }
}
