@import '@telia-front/sass-tokens/index.scss';

.chart-bar {
  display: flex;
  flex-wrap: wrap;
}

.chart-bar__inner {
  position: relative;
  width: 100%;
}

.chart-bar__tag {
  position: absolute;
  margin-top: -5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Title */

.chart-bar__title {
  flex: 0 0 100%;
  text-align: center;

  @media (min-width: $media-width-md-min) {
    .chart-bar--legend-right & {
      flex: 0 0 50%;
      margin-right: 50%;
    }
  }
}

.chart-bar__title-text {
  font-family: var(--type-font-family);
  font-weight: 700;
  text-transform: none;
}

/* Legend and alignment */

.chart-bar__graph-container {
  flex: 0 0 100%;
  overflow: hidden;

  @media (min-width: $media-width-md-min) {
    .chart-bar--legend-right & {
      flex: 0 0 50%;
    }
  }
}

.chart-bar__legend {
  margin-top: 1rem;

  .chart-bar--legend-right & {
    justify-content: flex-start;
    flex-direction: column;
  }

  @media (min-width: $media-width-md-min) {
    .chart-bar--legend-right & {
      flex: 0 0 50%;
      align-self: center;
      margin: 0;
      padding-left: 60px;
    }
  }
}

/* Tooltip */

.chart__tooltip-title {
  font-size: 16px;
  margin-bottom: 0.5em;
}

.chart__tooltip-list {
  padding: 0;
  list-style: none;
}

.chart__tooltip-list-item {
  display: flex;
  align-items: baseline;
}

.chart__tooltip-color {
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
}

.chart__tooltip-yVal {
  white-space: nowrap;
}

/* Scrollable */

.chart-bar__scrollable {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 14px 0 0;
  padding: 0;
  list-style: none;

  @media (min-width: $media-width-md-min) {
    justify-content: flex-end;
  }
}

.chart-bar__scrollable-item {
  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

/* D3 */

.chart-bar__svg {
  display: block;
  width: 100%;
  overflow: hidden;
}

.chart-bar__axis path,
.chart-bar__axis line {
  stroke: $grey;
}

.chart-bar__axis-y-grid path,
.chart-bar__axis-x path {
  display: none;
}

.chart-bar__axis-y path,
.chart-bar__axis-y2 path {
  stroke-width: 0;
}

.chart-bar__axis-y line,
.chart-bar__axis-y2 line,
.chart-bar__axis-y-grid line {
  stroke-width: 1;
  stroke: $grey;
}

.chart-bar__axis-y text,
.chart-bar__axis-y2 text {
  font-weight: 700;
  font-size: 12px;
  color: $black;
}

.chart-bar__axis-x line {
  stroke-width: 0;
}

.chart-bar__axis-x text {
  font-size: 12px;
  font-weight: 400;
  color: $black;
}

.chart-bar__stack-label {
  font-size: 12px;
  font-weight: 700;
  text-anchor: middle;
}
