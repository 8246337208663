@import '@telia-front/sass-tokens/index.scss';

// stylelint-disable-next-line
$carousel-paddings: (none 0px, xsmall 8px, small 16px, medium 24px, large 32px, xlarge 48px, xxlarge 64px);
$carousel-fade-width: 24px;

.carousel {
  --carousel-slide-overflow: 30px;
  --carousel-container-padding: #{$container-padding};
  --carousel-container-padding-left: var(--carousel-container-padding);
  --carousel-container-padding-right: var(--carousel-container-padding);
  --carousel-slide-gap: 16px;
  --carousel-slide-vertical-padding: 16px;
  --carousel-slide-horizontal-padding-left: calc(var(--carousel-slide-gap) / 2);
  --carousel-slide-horizontal-padding-right: calc(var(--carousel-slide-gap) / 2);

  @media (min-width: $media-width-sm-min) {
    --carousel-slide-overflow: 34px;
    --carousel-container-padding: #{$container-padding-sm};
    --carousel-slide-gap: 24px;
    --carousel-slide-vertical-padding: 24px;
  }

  @media (min-width: $media-width-lg-min) {
    --carousel-container-padding: #{$container-padding-lg};
  }

  @media (min-width: $media-width-xl-min) {
    --carousel-slide-overflow: 0px;
  }

  &:not(.carousel--has-container) {
    --carousel-container-padding: 0px;
  }

  &.carousel--align-center.carousel--has-container {
    --carousel-slide-overflow: 44px;

    @media (min-width: $media-width-sm-min) {
      --carousel-slide-overflow: 58px;
    }

    @media (min-width: $media-width-xl-min) {
      --carousel-slide-overflow: 0px;
    }
  }

  &.carousel--align-full {
    --carousel-slide-overflow: 0px;
  }

  &.carousel--center-slides {
    --carousel-slide-overflow: 0px;
  }

  &:not(.carousel--sales-argument),
  &.carousel--center-arrows-autoplay:not(.carousel--sales-argument) {
    margin-top: calc(var(--carousel-slide-vertical-padding) * -1);

    &.carousel--with-arrows.carousel--scrollable {
      margin-bottom: calc(var(--carousel-slide-vertical-padding) * -1);
    }
  }

  &.carousel--with-arrows {
    margin-top: 0;
  }

  position: relative;
  display: flex;
  flex-direction: column;
}

.carousel--has-container.carousel--fade {
  background-color: inherit;
  mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) calc((100% - $page-max-width) / 2 - $carousel-fade-width),
      rgb(0, 0, 0) calc((100% - $page-max-width) / 2)
    ),
    linear-gradient(to left, rgba(0, 0, 0, 0) calc((100% - $page-max-width) / 2 - $carousel-fade-width), rgb(0, 0, 0) calc((100% - $page-max-width) / 2));
  // Must be set the amount of times we have different gradients in mask, otherwise older webkit browsers will not render it due to how autoprefixer translates it to -webkit-mask-composite.
  mask-composite: intersect, intersect;
}

.carousel--sales-argument {
  --carousel-slide-vertical-padding: 14px;

  background-color: var(--color-brand-primary-bg-subtle);
  color: var(--color-neutral-text);
}

.carousel--full-width {
  margin-left: calc(var(--carousel-container-padding-left) * -1);
  margin-right: calc(var(--carousel-container-padding-right) * -1);

  @each $size, $padding in $card-paddings {
    .card__content--padding-#{$size} > & {
      --carousel-container-padding: #{$padding};
    }

    .card__content--padding-horizontal-#{$size} > & {
      --carousel-container-padding: #{$padding};
    }
  }

  &.carousel--full-width-container {
    --carousel-container-padding: #{$container-padding};

    @media (min-width: $media-width-sm-min) {
      --carousel-container-padding: #{$container-padding-sm};
    }

    @media (min-width: $media-width-lg-min) {
      --carousel-container-padding: #{$container-padding-lg};
    }
  }

  @each $size, $padding in $carousel-paddings {
    &.carousel--full-width-#{$size} {
      --carousel-container-padding: #{$padding};
    }

    &.carousel--full-width-left-#{$size} {
      --carousel-container-padding-left: #{$padding};
    }

    &.carousel--full-width-right-#{$size} {
      --carousel-container-padding-right: #{$padding};
    }
  }
}

.carousel__container {
  padding-left: var(--carousel-container-padding-left);
  padding-right: var(--carousel-container-padding-right);
}

.carousel__container.carousel__slider-container {
  max-width: 100%;
}

.carousel__slider {
  display: flex;
  overflow: auto;
  overscroll-behavior: none auto;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
  outline-offset: -8px;

  scroll-padding-left: max(
    calc((100% - $page-max-width) / 2 + var(--carousel-container-padding-left) - (var(--carousel-slide-gap) / 2)),
    calc(var(--carousel-container-padding-left) - (var(--carousel-slide-gap) / 2))
  );
  scroll-padding-right: max(
    calc((100% - $page-max-width) / 2 + var(--carousel-container-padding-right) - (var(--carousel-slide-gap) / 2)),
    calc(var(--carousel-container-padding-right) - (var(--carousel-slide-gap) / 2))
  );

  width: calc(100% + var(--carousel-slide-gap));
  margin-left: calc(var(--carousel-slide-gap) / 2 * -1);
  margin-right: calc(var(--carousel-slide-gap) / 2 * -1);

  .carousel--has-container & {
    width: calc(100% + var(--carousel-container-padding-left) + var(--carousel-container-padding-right));
    margin-left: calc(var(--carousel-container-padding-left) * -1);
    margin-right: calc(var(--carousel-container-padding-right) * -1);
  }

  .carousel--with-arrows & {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.carousel__item {
  display: flex;
  width: 100%;
  scroll-snap-align: start;

  .carousel--align-center & {
    scroll-snap-align: center;
  }

  .carousel--scrollable & {
    padding-left: var(--carousel-slide-horizontal-padding-left);
    padding-right: var(--carousel-slide-horizontal-padding-right);
  }

  .carousel--scrollable &,
  .carousel--sales-argument & {
    padding-top: var(--carousel-slide-vertical-padding);
    padding-bottom: var(--carousel-slide-vertical-padding);
  }

  .carousel--center-arrows-scrollable & {
    --carousel-slide-horizontal-padding-left: 2rem;
    --carousel-slide-horizontal-padding-right: 2rem;

    @media (min-width: $media-width-sm-min) {
      --carousel-slide-horizontal-padding-left: 1.25rem;
      --carousel-slide-horizontal-padding-right: 1.25rem;
    }
  }

  .carousel--center-arrows-autoplay.carousel--with-arrows & {
    --carousel-slide-horizontal-padding-right: 3.5rem;

    @media (min-width: $media-width-sm-min) {
      --carousel-slide-horizontal-padding-right: 2.75rem;
    }
  }

  .carousel--center-slides & {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }
  }

  .carousel--scrollable.carousel--has-container & {
    &:first-child {
      margin-left: calc(var(--carousel-container-padding-left) - (var(--carousel-slide-gap) / 2));

      @media (min-width: $media-width-xl-min) {
        margin-left: max(
          calc((100% - $page-max-width) / 2 + var(--carousel-container-padding-left) - (var(--carousel-slide-gap) / 2)),
          calc(var(--carousel-container-padding-left) - (var(--carousel-slide-gap) / 2))
        );
      }
    }

    &:last-child {
      margin-right: calc(var(--carousel-container-padding-right) - (var(--carousel-slide-gap) / 2));

      @media (min-width: $media-width-xl-min) {
        margin-right: max(
          calc((100% - $page-max-width) / 2 + var(--carousel-container-padding-right) - (var(--carousel-slide-gap) / 2)),
          calc(var(--carousel-container-padding-right) - (var(--carousel-slide-gap) / 2))
        );
      }
    }
  }

  @for $i from 1 through 6 {
    $value: calc((100% - var(--carousel-slide-overflow)) / $i);
    $has-container-value: calc(
      (
          min($page-max-width, 100%) - var(--carousel-container-padding-left) - var(--carousel-container-padding-right) +
            var(--carousel-slide-gap) - var(--carousel-slide-overflow)
        ) /
        $i
    );

    .carousel--slides-#{$i} & {
      max-width: $value;
      min-width: $value;
    }

    .carousel--has-container.carousel--slides-#{$i} & {
      max-width: $has-container-value;
      min-width: $has-container-value;
    }
  }
}

.carousel__show-more {
  padding: 20px 10px;
  margin-bottom: 0;
}

.carousel__slider-nav {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;

  &.carousel__slider-nav--link {
    gap: 32px;
  }

  &.carousel__slider-nav--link.carousel__slider-nav--small {
    gap: 24px;
  }
}

.carousel__nav {
  .carousel__slider-nav--center & {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.carousel__nav--prev {
  .carousel__slider-nav--center & {
    left: var(--carousel-container-padding-left);

    @media (min-width: $media-width-sm-min) {
      left: max(calc(100% - $page-max-width) / 2 + var(--carousel-container-padding-left) - 21px, 3px);
    }
  }
}

.carousel__nav--next {
  .carousel__slider-nav--center & {
    right: var(--carousel-container-padding-right);

    @media (min-width: $media-width-sm-min) {
      right: max(calc(100% - $page-max-width) / 2 + var(--carousel-container-padding-right) - 21px, 3px);
    }
  }
}

.carousel__nav--playpause {
  .carousel__slider-nav & {
    .carousel--with-arrows & {
      margin-right: 0.5rem;
    }
  }

  .carousel__slider-nav--center & {
    margin-right: 0;
    right: var(--carousel-container-padding-right);

    .carousel--with-arrows & {
      margin-right: 0;
      right: calc(var(--carousel-container-padding-left) + var(--type-small-font-size) + 0.5rem);

      @media (min-width: $media-width-sm-min) {
        right: max(calc(100% - $page-max-width) / 2 + var(--carousel-container-padding-right) - 21px, 3px + var(--type-small-font-size) + 0.5rem);
      }

      @media (min-width: $media-width-xl-min) {
        right: max((100% - 80.5rem) / 2 + var(--carousel-container-padding-right) + 4px, 3px + var(--type-small-font-size) + 0.5rem);
      }
    }

    @media (min-width: $media-width-sm-min) {
      right: max(calc(100% - $page-max-width) / 2 + var(--carousel-container-padding-right) - 21px, 3px);
    }
  }
}

.carousel__after-slides-content {
  .carousel.carousel--with-arrows &,
  .carousel--center-arrows-scrollable &,
  .carousel--center-arrows-autoplay & {
    padding-top: var(--carousel-slide-vertical-padding);
    margin-top: calc(var(--carousel-slide-vertical-padding) * -1);
  }

  .carousel.carousel--with-arrows & {
    padding-bottom: var(--carousel-slide-vertical-padding);
  }
}

.carousel__before-slides-content {
  .carousel--center-arrows-scrollable &,
  .carousel--center-arrows-autoplay & {
    padding-bottom: var(--carousel-slide-vertical-padding);
    margin-bottom: calc(var(--carousel-slide-vertical-padding) * -1);
    padding-top: var(--carousel-slide-vertical-padding);
  }
}

.carousel__top-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;

  .carousel:not(.carousel--center-arrows-scrollable, .carousel--center-arrows-autoplay, .carousel--with-arrows) & {
    margin-top: var(--carousel-slide-vertical-padding);
  }
}
