@import '@telia-front/sass-tokens/index.scss';

.datefield__button {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 16px 9px 9px;
  background: transparent;
  border: 0;
  border-radius: 0;
  appearance: none;
  font-size: 24px;
  // disable focus outline because focus is shown by pseudo-focused input styles
  outline: none;
}
